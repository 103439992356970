import React from 'react'
import Layout from '../../components/Layout'
import './200.scss'

const Loading = () => (
  <div id="loading-page-container">
    <Layout>
      <div id="loading-page">
        <h1>Loading</h1>
      </div>
    </Layout>
  </div>
)

export default Loading